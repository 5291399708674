import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGenericResponse } from '../interfaces/generic.interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IRequestDemonstration } from '../interfaces/request-demonstration.interface';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  apiUrl = `${environment.apiUrl}/demoRequest`;
  handleErrorCustom = { handleErrorCustom: 'true' };

  scrollToForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showDemonstrationForm = signal(true);

  constructor(private http: HttpClient) {}

  requestDemonstration(
    value: IRequestDemonstration
  ): Observable<IGenericResponse<boolean>> {
    return this.http.post<IGenericResponse<boolean>>(`${this.apiUrl}`, value, {
      headers: this.handleErrorCustom
    });
  }
}
